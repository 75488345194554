import axios from './axiosInit'
const getBookingList = async (options, status, bookingType, id, searchValue) =>
{
    try
    {
        if (searchValue && id)
        {
            const result = await axios.get('boat_bookings/list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
                + '&status=' + status + '&booking_type=' + bookingType + '&owner_id=' + id + '&search=' + searchValue
            )
            return result
        }
        else if (searchValue)
        {
            const result = await axios.get('boat_bookings/list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
                + '&status=' + status + '&booking_type=' + bookingType + '&search=' + searchValue
            )
            return result
        }
        else
        {
            const result = await axios.get('boat_bookings/list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
                + '&status=' + status + '&booking_type=' + bookingType
            )
            return result
        }
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}



const getUsersBookingsList = async (id, status) =>
{
    try
    {
        let url;
        if (status)
        {
            url = 'users/booking-list?id=' + id + '&status=' + status
        }
        else
        {
            url = 'users/booking-list?id=' + id
        }
        const result = await axios.get(url)
        return result
    } catch (e)
    {
        console.log(e)
    }
}



const getOwnerBookingsList = async (id, status,search) =>
{
    try
    {
        let url;
        if (status)
        {
            url = 'users/booking-list?owner_id=' + id + '&status=' + status
        }
        else if (search)
        {
            url = 'users/booking-list?owner_id=' + id + '&search=' + search
        }
        else
        {
            url = 'users/booking-list?owner_id=' + id
        }
        const result = await axios.get(url)
        return result
    } catch (e)
    {
        console.log(e)
    }
}


const getBoatingList = async (id, status,search) =>
{
    try
    {
        let url = 'boat/onboarding/list?owner_id=' + id
        if (status)
        {
            url = url + '&status=' + status
        }
        if (search)
        {
            url = url + '&status=' + status + '&search=' + search
        }
        const result = await axios.get(url)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}



const viewBooking = async (id) =>
{

    try
    {
        const result = await axios.get('boat_bookings/list?id=' + id)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateApplicationStatus = async (data, url) =>
{

    try
    {
        const result = await axios.post(url, data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

export default {
    getBookingList,
    updateApplicationStatus,
    viewBooking,
    getUsersBookingsList,
    getOwnerBookingsList,
    getBoatingList
}